import React from 'react'
import { IRoute } from '../interfaces/router/route.interface'
import { routersPath } from './routes-path'
import { EUserRoles } from '../enums/roles'
import { DocGenPreview } from '../pages/DocGenPreview/DocGenPreview'
import { useCrmPermission } from '../store/selectors/crmInfo'

const TelephonyPage = React.lazy(() => import('../pages/TelephonyPage/TelephonyPage'))
const SalaryDebtPage = React.lazy(() => import('../pages/Salary/SalaryDebt/SalaryDebtPage'))
const SummaryTestResults = React.lazy(
  () => import('../pages/TestsPage/SummaryTestResults/SummaryTestResults'),
)
const TestResultPage = React.lazy(() => import('../pages/TestsPage/TestResultPage/TestResultPage'))
const ManagerTestPage = React.lazy(
  () => import('../pages/ManagerTests/ManagerTest/ManagerTestPage'),
)
const UpdateTest = React.lazy(() => import('../pages/TestsPage/UpdateTest/UpdateTest'))
const AddTest = React.lazy(() => import('../pages/TestsPage/AddTest/AddTest'))
const OnlineUsersPage = React.lazy(() => import('../pages/OnlineUsers/OnlineUsersPage'))
const MainPage = React.lazy(() => import('../pages/MainPage/MainPage'))
const StatPage = React.lazy(() => import('../pages/StatPage/StatPage'))
const StatManagerPage = React.lazy(() => import('../pages/StatManagerPage/StatManagerPage'))
const ManagerTransmitPage = React.lazy(() => import('../pages/TaskPage/ManagerTransmitPage'))
const UpdateTaskPage = React.lazy(() => import('../pages/TaskPage/Update/UpdateTaskPage'))
const ManagerRecallPage = React.lazy(() => import('../pages/TaskPage/ManagerRecallPage'))
const ManagerTaskPage = React.lazy(() => import('../pages/TaskPage/ManagerTaskPage'))
const BaseImportPage = React.lazy(() => import('../pages/BaseImportPage/BaseImportPage'))
const ClientFindPage = React.lazy(() => import('../pages/ClientFindPage/ClientFindPage'))
const DoubleFindPage = React.lazy(() => import('../pages/DoubleFindPage/DoubleFindPage'))
const ManagerSettingsPage = React.lazy(
  () => import('../pages/ManagerSettingsPage/ManagerSettingsPage'),
)
const TestsPage = React.lazy(() => import('../pages/TestsPage/TestsPage'))
const ManagersPage = React.lazy(() => import('../pages/ManagersPage/ManagersPage'))
const ScriptsPage = React.lazy(() => import('../pages/ScriptsPage/ScriptsPage'))
const BasesPage = React.lazy(() => import('../pages/Bases/Bases'))
const HistoryPage = React.lazy(() => import('../pages/HistoryPage'))
const ManagerTestsPage = React.lazy(() => import('../pages/ManagerTests/ManagerTestsPage'))
const BasePage = React.lazy(() => import('../pages/BasePage/BasePage'))
const ClientPage = React.lazy(() => import('../pages/ClientPage/ClientPage'))
const CreateScriptPage = React.lazy(() => import('../pages/ScriptCreatePage/ScriptCreatePage'))
const ScriptEditPage = React.lazy(() => import('../pages/ScriptEditPage/ScriptEditPage'))
const DocGen = React.lazy(() => import('../pages/DocGen/DocGen'))
const DocPreview = React.lazy(() => import('../pages/DocGen/DocPreview'))
const SalaryInputPage = React.lazy(() => import('../pages/Salary/SalaryInput/SalaryInputPage'))
const SalaryOutputPage = React.lazy(() => import('../pages/Salary/SalaryOutput/SalaryOutputPage'))
const SalaryCryptoPage = React.lazy(() => import('../pages/Salary/SalaryCrypto/SalaryCryptoPage'))
const WhiteListPage = React.lazy(() => import('../pages/WhiteListPage/WhiteListPage'))
const SummaryReportPage = React.lazy(
  () => import('../pages/Salary/SummaryReport/SummaryReportPage'),
)
const DocTemplates = React.lazy(() => import('../pages/DocTemplates/DocTemplates'))
const CyberSecPage = React.lazy(() => import('../pages/CyberSecPage/CyberSecPage'))

export const privateRoutes = (): IRoute[] => {
  const permissions = useCrmPermission()
  return [
    {
      roles: [EUserRoles.ADMIN],
      path: routersPath.STAT_PAGE,
      component: <StatPage />,
      title: 'Статистика',
      show: permissions.statistic,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.STAT_MANAGER_PAGE,
      component: <StatManagerPage />,
      title: 'Статистика менеджера',
      show: permissions.statistic,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.MANAGERS_PAGE,
      component: <ManagersPage />,
      title: 'Менеджеры',
      show: true,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.MANAGER, EUserRoles.OPERATOR],
      path: routersPath.SCRIPTS_PAGE,
      component: <ScriptsPage />,
      title: 'Скрипты',
      show: permissions.scripts,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.MANAGER, EUserRoles.OPERATOR],
      path: routersPath.SCRIPT_CREATE,
      component: <CreateScriptPage />,
      title: 'Создать скрипт',
      show: permissions.scripts,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.MANAGER, EUserRoles.OPERATOR],
      path: routersPath.SCRIPT_EDIT,
      component: <ScriptEditPage />,
      title: 'Изменить скрипт',
      show: permissions.scripts,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.MAIN_PAGE,
      component: <MainPage />,
      title: 'Задача',
      show: true,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.BASES,
      component: <BasesPage />,
      title: 'Клиенты',
      show: true,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.BASES_IMPORT,
      component: <BaseImportPage />,
      title: 'Импорт баз',
      show: true,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.CYBERSEC,
      component: <CyberSecPage />,
      title: 'CyberSec',
      show: true,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.BASES_CLIENT_FIND,
      component: <ClientFindPage />,
      title: 'Поиск клитента',
      show: true,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.BASES_DOUBLE_FIND,
      component: <DoubleFindPage />,
      title: 'Поиск дублей',
      show: true,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.MANAGER_SETTINGS,
      component: <ManagerSettingsPage />,
      title: 'Предустановки',
      show: true,
    },
    //ADMIN TESTS
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.TESTS,
      component: <TestsPage />,
      title: 'Тесты',
      show: permissions.tests,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.ADD_TEST,
      component: <AddTest />,
      title: 'Добавить тест',
      show: permissions.tests,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.UPDATE_TEST,
      component: <UpdateTest />,
      title: 'Обновить тест',
      show: permissions.tests,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.RESULT_TEST,
      component: <TestResultPage />,
      title: 'Результаты теста',
      show: permissions.tests,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.SUMMARY_RESULT_TEST,
      component: <SummaryTestResults />,
      title: 'Суммарные результаты теста',
      show: permissions.tests,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.BASE,
      component: <BasePage />,
      title: 'Базы',
      show: true,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.CLIENT_PAGE,
      component: <ClientPage />,
      title: 'Клиент',
      show: true,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.MANAGER, EUserRoles.OPERATOR],
      path: routersPath.DOC_GEN,
      component: <DocGen />,
      title: 'Генератор документов',
      show: permissions.generator,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.MANAGER, EUserRoles.OPERATOR],
      path: routersPath.NEW_DOC_GEN,
      component: <DocTemplates />,
      title: 'Новый генератор документов',
      show: permissions.generator,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.MANAGER, EUserRoles.OPERATOR],
      path: routersPath.NEW_DOC_PREVIEW,
      component: <DocGenPreview />,
      title: 'Новый предпросмотр документов',
      show: permissions.generator,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.MANAGER, EUserRoles.OPERATOR],
      path: routersPath.DOC_PREVIEW,
      component: <DocPreview />,
      title: 'Генератор предпросмотр',
      show: permissions.generator,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.WHITE_LIST,
      component: <WhiteListPage />,
      title: 'Вывод крипты',
      show: permissions.salary,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.ONLINE_USERS,
      component: <OnlineUsersPage />,
      title: 'Юзеры онлайн',
      show: true,
    },
    {
      roles: [EUserRoles.ADMIN, EUserRoles.OPERATOR],
      path: routersPath.TELEPHONY,
      component: <TelephonyPage />,
      title: 'Телефония',
      show: true,
    },

    //MANAGER PAGES
    {
      roles: [EUserRoles.MANAGER],
      path: routersPath.HISTORY_PAGE,
      component: <HistoryPage />,
      title: 'История',
      show: true,
    },
    {
      roles: [EUserRoles.MANAGER],
      path: routersPath.TASK_PAGE,
      component: <ManagerTaskPage />,
      title: 'Задача',
      show: true,
    },
    {
      roles: [EUserRoles.MANAGER],
      path: routersPath.TASK_PAGE_TRANSMIT,
      component: <ManagerTransmitPage />,
      title: 'Задача',
      show: true,
    },
    {
      roles: [EUserRoles.MANAGER],
      path: routersPath.TASK_PAGE_RECALL,
      component: <ManagerRecallPage />,
      title: 'Задача',
      show: true,
    },
    {
      roles: [EUserRoles.MANAGER],
      path: routersPath.TASK_PAGE_UPDATE,
      component: <UpdateTaskPage />,
      title: 'Задача',
      show: true,
    },
    //MANAGER TEST
    {
      roles: [EUserRoles.MANAGER],
      path: routersPath.MANAGER_TESTS,
      component: <ManagerTestsPage />,
      title: 'Тесты менеджера',
      show: permissions.tests,
    },
    {
      roles: [EUserRoles.MANAGER],
      path: routersPath.MANAGER_TEST,
      component: <ManagerTestPage />,
      title: 'Тесты менеджера',
      show: permissions.tests,
    },
    {
      roles: [EUserRoles.MANAGER],
      path: routersPath.MANAGER_SCRIPTS,
      component: <ScriptsPage />,
      title: 'Скрипты менеджера',
      show: permissions.scripts,
    },
  ]
}

export const salaryAdminRouth = (): IRoute[] => {
  const permissions = useCrmPermission()
  return [
    {
      roles: [EUserRoles.ADMIN],
      path: routersPath.SALARY_INPUT,
      component: <SalaryInputPage />,
      title: 'Зарплата ввод',
      show: permissions.salary,
    },
    {
      roles: [EUserRoles.ADMIN],
      path: routersPath.SALARY_OUTPUT,
      component: <SalaryOutputPage />,
      title: 'Зарплата вывод',
      show: permissions.salary,
    },
    {
      roles: [EUserRoles.ADMIN],
      path: routersPath.SALARY_CRYPTO_CHANGE,
      component: <SalaryCryptoPage />,
      title: 'Вывод крипты',
      show: permissions.salary,
    },
    {
      roles: [EUserRoles.ADMIN],
      path: routersPath.SALARY_SUMMARY_REPORT,
      component: <SummaryReportPage />,
      title: 'Суммарный отчет',
      show: permissions.salary,
    },
    {
      roles: [EUserRoles.ADMIN],
      path: routersPath.SALARY_DEBT,
      component: <SalaryDebtPage />,
      title: 'Долги',
      show: permissions.salary,
    },
  ]
}
