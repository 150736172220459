import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './AdminHeader.module.scss'
import { useCrmSelector, useUserSelector } from '../../../store/selectors/auth'
import { AdminProfileDrop } from '../../../menu/dropdown/adminProfile/adminProfile'
import BellNotification from '../../ui/BellNotification/BellNotification'
import Search from 'antd/lib/input/Search'
import { Tabs } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import useCurrentTab from '../../../hooks/useCurrentTab'
import { ITabs } from './adminTabs'
import { routersPath } from '../../../router/routes-path'
import HeaderLogo from '../../ui/headerLogo/HeaderLogo'
import { useMessage } from '../../../hooks/useMessage'
import { shortDateFormat } from '../../../utils/time'
import moment from 'moment'
import { useAppDispatch } from '../../../store/hooks'
import { getFirstNotifications } from '../../../store/notifications/thunks'
import PayBtn from '../../ui/PayBtn/PayBtn'
import BannerSection from '../../BannerSection/BannerSection'

const { TabPane } = Tabs

interface IProps {
  tabs: ITabs[]
}

const AdminHeader: React.FC<IProps> = ({ tabs }) => {
  const [search, setSearch] = useState('')
  const { error } = useMessage()
  const navigate = useNavigate()
  const user = useUserSelector()
  const crm = useCrmSelector()
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const activeTab = useCurrentTab(pathname, tabs)
  const onSearch = useCallback(() => {
    if (search) {
      navigate(routersPath.BASES_CLIENT_FIND, {
        state: {
          search: search,
        },
      })
      setSearch('')
    }
  }, [search])

  useEffect(() => {
    dispatch(getFirstNotifications()).then()
  }, [])

  useEffect(() => {
    const checkNotifications = () => {
      if (!crm?.isActive) return 'СRM не активна, обратитесь в поддержку.'
      if (moment().isAfter(moment(crm?.billingDate)))
        return `Подписка на CRM окончена ${shortDateFormat(crm?.billingDate)}`
      if (moment().add(7, 'day').isAfter(moment(crm?.billingDate)))
        return `Подписка скоро закончиться. Дата окончания: ${shortDateFormat(crm?.billingDate)}`
      return null
    }
    const isNotification = checkNotifications()
    if (isNotification) error(isNotification, 'ОПЛАТИТЕ CRM')
  }, [crm])

  const label = useMemo(() => {
    if (!crm?.isActive) return <div className={styles.subscription}>СRM НЕ АКТИВНА</div>
    if (moment().isAfter(moment(crm?.billingDate)))
      return <div className={styles.subscription}>ОПЛАТИТЕ CRM</div>
    if (moment().add(7, 'day').isAfter(moment(crm?.billingDate)))
      return (
        <div className={styles.subscription}>{`Подписка до: ${shortDateFormat(
          crm?.billingDate,
        )}`}</div>
      )
    return (
      <div className={styles.status}>{`Подписка до: ${shortDateFormat(crm?.billingDate)}`}</div>
    )
  }, [crm])

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <span className={styles.logo}>
          <HeaderLogo />
        </span>
        {label}
        <PayBtn />
        {user && (
          <div className={styles.menu}>
            <BellNotification />
            <div className={styles.dropdown}>
              <AdminProfileDrop />
            </div>
          </div>
        )}
      </div>
      <BannerSection />
      <div className={styles.bottom}>
        <Tabs className={styles.tabs} defaultActiveKey='0' activeKey={String(activeTab)}>
          {tabs?.map((el, index) => (
            <TabPane tab={el.title} key={index} />
          ))}
        </Tabs>
        <Search
          className={styles.input}
          placeholder='Поиск клиента'
          allowClear
          onSearch={onSearch}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </div>
  )
}

export default memo(AdminHeader)
