export enum ImgType {
  SIGNATURE = 'signature',
  FOTO = 'foto',
  STAMP = 'stamp',
  ADDITION = 'addition',
}

export enum SignatureType {
  SIGN1 = 'sign1',
  SIGN2 = 'sign2',
  SIGN3 = 'sign3',
  SIGN4 = 'sign4',
  SIGN5 = 'sign5',
  SIGN6 = 'sign6',
  SIGN7 = 'sign7',
  SIGN8 = 'sign8',
  SIGN9 = 'sign9',
  SIGN10 = 'sign10',
  SIGN11 = 'sign11',
  SIGN12 = 'sign12',
}

export enum StampType {
  ABSOLUTE = 'absolute',
  AKBARS = 'akbars',
  ALFA = 'alfa',
  ATB = 'atb',
  AVANGARD = 'avangard',
  BKS = 'bks',
  BSPB = 'bspb',
  C2BR = 'c2br',
  CBR = 'cbr',
  CITY = 'city',
  COAST = 'coast',
  DOMRF = 'domrf',
  FSB = 'fsb',
  FSB2 = 'fsb2',
  GAZPROM = 'gazprom',
  GENBANK = 'genbank',
  HLINOV = 'HLINOV',
  HOMECREDIT = 'homecredit',
  KAZAH = 'kazah',
  LOCKOBANK = 'lockobank',
  MINBANK = 'minbank',
  MKB = 'mkb',
  MTS = 'mts',
  MVD_KAZAH = 'mvd_kazah',
  MVD = 'mvd',
  MVD2 = 'mvd2',
  MVD3 = 'mvd3',
  NBK = 'nbk',
  NIKO = 'niko',
  OPEN = 'open',
  OTP = 'otp',
  POCHTA = 'pochta',
  PROC = 'proc',
  PSBANK = 'psbank',
  RAIFFEISEN = 'raiffeisen',
  RENCREDIT = 'rencredit',
  RNCB = 'rncb',
  ROSBANK = 'rosbank',
  ROSTRAH = 'rostrah',
  RSB = 'rsb',
  RSHB = 'rshb',
  SBER = 'sber',
  SETELEM = 'setelem',
  SKBBANK = 'skbbank',
  SOVCOMBANK = 'sovcombank',
  TINKOFF = 'tinkoff',
  UBRR = 'ubrr',
  UNICREDIT = 'unicredit',
  URALSIB = 'uralsib',
  VBR = 'vbr',
  VTB = 'vtb',
  ZAPSIB = 'zapsib',
}

export enum PhotoType {
  PHOTO1 = 'photo1',
  PHOTO2 = 'photo2',
  PHOTO3 = 'photo3',
  PHOTO4 = 'photo4',
  PHOTO5 = 'photo5',
  PHOTO6 = 'photo6',
  PHOTO7 = 'photo7',
  PHOTO8 = 'photo8',
  PHOTO9 = 'photo9',
  PHOTO10 = 'photo10',
  PHOTO11 = 'photo11',
  PHOTO12 = 'photo12',
  PHOTO13 = 'photo13',
  PHOTO14 = 'photo14',
  PHOTO15 = 'photo15',
  PHOTO16 = 'photo16',
  PHOTO17 = 'photo17',
  PHOTO18 = 'photo18',
  PHOTO19 = 'photo19',
  PHOTO20 = 'photo20',
  PHOTO21 = 'photo21',
  PHOTO22 = 'photo22',
  PHOTO23 = 'photo23',
  PHOTO24 = 'photo24',
  PHOTO25 = 'photo25',
  PHOTO26 = 'photo26',
  PHOTO27 = 'photo27',
  PHOTO28 = 'photo28',
  PHOTO29 = 'photo29',
  PHOTO30 = 'photo30',
  PHOTO31 = 'photo31',
  PHOTO32 = 'photo32',
  PHOTO33 = 'photo33',
  PHOTO34 = 'photo34',
  PHOTO35 = 'photo35',
  PHOTO36 = 'photo36',
  PHOTO37 = 'photo37',
  PHOTO38 = 'photo38',
}

export enum AdditionType {
  BARCODE = 'barcode',
  BORDER = 'border',
  LINE_H = 'line_h',
  CIRCLE = 'circle',
  CIRCLE_D = 'circle_d',
  SQUARE = 'square',
  SQUARE_D = 'square_d',
}
