import { EUserRoles } from '../enums/roles'

export enum routersPath {
  LOGIN_PAGE = '/',
  NOT_FOUND = '/not_found',
  MAIN_PAGE = '/main',
  DISABLE_PAGE = '/disable',
  //bases
  BASES = '/bases',
  BASE = '/base',
  BASES_IMPORT = '/bases_import',
  BASES_DOUBLE_FIND = '/bases_double_find',
  //settings
  MANAGER_SETTINGS = '/manager/settings',
  MANAGERS_PAGE = '/managers',
  ONLINE_USERS = '/online_users',
  WHITE_LIST = '/white_list',
  //tests
  TESTS = '/tests',
  ADD_TEST = '/tests/add',
  UPDATE_TEST = '/tests/update',
  RESULT_TEST = '/tests/result',
  SUMMARY_RESULT_TEST = '/tests/summary_result',
  //scripts
  SCRIPTS_PAGE = '/scripts',
  SCRIPT_CREATE = '/script/create',
  SCRIPT_EDIT = '/script/edit',
  //telephony
  TELEPHONY = '/telephony',
  //manager pages
  TASK_PAGE = '/task',
  TASK_PAGE_TRANSMIT = '/task/transmit',
  TASK_PAGE_RECALL = '/task/recall',
  TASK_PAGE_UPDATE = '/task/update',
  HISTORY_PAGE = '/history',
  MANAGER_TESTS = '/manager/tests',
  MANAGER_TEST = '/manager/test',
  MANAGER_SCRIPTS = '/manager/scripts',
  //client page
  CLIENT_PAGE = '/client',
  BASES_CLIENT_FIND = '/bases_client_find',
  //stat
  STAT_PAGE = '/stat',
  STAT_MANAGER_PAGE = '/stat/manager',
  //doc generator
  DOC_GEN = '/doc_gen',
  DOC_PREVIEW = '/doc_preview',
  NEW_DOC_GEN = '/new_doc_gen',
  NEW_DOC_PREVIEW = '/new_doc_preview',
  //salary
  SALARY_INPUT = '/salary/input',
  SALARY_OUTPUT = '/salary/output',
  SALARY_CRYPTO_CHANGE = '/salary/crypto',
  SALARY_SUMMARY_REPORT = '/salary/summary-report',
  SALARY_CALC = '/salary/calc',
  SALARY_DEBT = '/salary/debt',
  //cybersec
  CYBERSEC = '/cybersec',
}

export function getDefaultPathByRole(role?: number): routersPath {
  switch (role) {
    case EUserRoles.ADMIN:
      return routersPath.MAIN_PAGE
    case EUserRoles.OPERATOR:
      return routersPath.MAIN_PAGE
    case EUserRoles.SUPER_ADMIN:
      return routersPath.MAIN_PAGE
    case EUserRoles.MANAGER:
      return routersPath.TASK_PAGE
    default:
      return routersPath.LOGIN_PAGE
  }
}
