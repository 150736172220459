import React, { useEffect } from 'react'
import styles from './ManagerHeader.module.scss'
import HeaderLogo from '../../ui/headerLogo/HeaderLogo'
import { ManagerProfileDrop } from '../../../menu/dropdown/managerProfile/managerProfile'
import { useNavigate } from 'react-router-dom'
import { routersPath } from '../../../router/routes-path'
import { getTestsCount } from '../../../store/tests/thunk'
import { useAppDispatch } from '../../../store/hooks'
import { useTestsCountSelector } from '../../../store/selectors/tests'
import BannerSection from '../../BannerSection/BannerSection'
import { useCrmPermission } from '../../../store/selectors/crmInfo'

const ManagerHeader: React.FC = () => {
  const dispatch = useAppDispatch()
  const testsCount = useTestsCountSelector()
  const permissions = useCrmPermission()

  useEffect(() => {
    dispatch(getTestsCount())
  }, [])

  const navigate = useNavigate()
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <HeaderLogo />
        <div className={styles.dropdown}>
          {permissions.tests && (
            <div className={styles.btnWrapper} onClick={() => navigate(routersPath.MANAGER_TESTS)}>
              <div className={styles.testBtn}>Тесты</div>
              {testsCount > 0 && <div className={styles.tag}>{testsCount}</div>}
            </div>
          )}
          <ManagerProfileDrop />
        </div>
      </div>
    </div>
  )
}

export default ManagerHeader
