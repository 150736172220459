import { ReloadOutlined } from '@ant-design/icons'
import React, { useCallback } from 'react'
import styles from './PayModal.module.scss'

interface Props {
  setReload: React.Dispatch<React.SetStateAction<number>>
}

const ApiError: React.FC<Props> = ({ setReload }) => {
  const reloadHandler = useCallback(() => {
    setReload((prev) => prev + 1)
  }, [setReload])

  return (
    <div className={styles.errorWrapper}>
      <div>
        Произла ошибка сервиса FlyPay.
        <br /> Попробуйте повторить запрос.
      </div>
      <ReloadOutlined className={styles.reload} onClick={reloadHandler} />
    </div>
  )
}

export default ApiError
