import React from 'react'
import cls from './BannerSection.module.scss'

const BannerSection: React.FC = () => {
  return (
    <div className={cls.container}>
      <a href='http://t.me/carl_obnaal' target='_blank' rel='noreferrer'>
        <img className={cls.banner} src='/assets/webp/banner2.webp' alt='banner2' />
      </a>
      <a href='http://t.me/moriarti_base' target='_blank' rel='noreferrer'>
        <video className={cls.banner} src='/assets/webm/banner1.webm' loop autoPlay />
      </a>
      <a href='https://t.me/robinZonsim' target='_blank' rel='noreferrer'>
        <video className={cls.banner} src='/assets/webm/banner3.webm' loop autoPlay />
      </a>
    </div>
  )
}

export default BannerSection
