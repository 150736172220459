import { NavLink } from 'react-router-dom'
import { routersPath } from '../../../router/routes-path'
import {
  CarryOutOutlined,
  DatabaseOutlined,
  DownOutlined,
  FileAddOutlined,
  FileTextOutlined,
  HomeOutlined,
  SearchOutlined,
  SettingFilled,
  SettingOutlined,
  SnippetsOutlined,
  SwapOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import DropdownUI from '../../ui/DropDownUI/DropdownUi'
import { Menu, Space } from 'antd'
import React from 'react'
import { ITabs } from './adminTabs'
import { useCrmPermission } from '../../../store/selectors/crmInfo'

export const operatorTabs = (): ITabs[] => {
  const permissions = useCrmPermission()

  return [
    {
      show: true,
      title: (
        <NavLink to={routersPath.MAIN_PAGE}>
          <HomeOutlined />
          Главная
        </NavLink>
      ),
      pages: [routersPath.MAIN_PAGE],
    },
    {
      show: true,
      title: (
        <DropdownUI
          menu={
            <Menu
              items={[
                {
                  key: routersPath.BASES,
                  label: (
                    <NavLink to={routersPath.BASES}>
                      <span>Мои базы</span>
                    </NavLink>
                  ),
                  icon: <DatabaseOutlined />,
                },
                {
                  key: routersPath.BASES_IMPORT,
                  label: (
                    <NavLink to={routersPath.BASES_IMPORT}>
                      <span>Импорт базы</span>
                    </NavLink>
                  ),
                  icon: <FileAddOutlined />,
                },
                {
                  key: routersPath.BASES_CLIENT_FIND,
                  label: (
                    <NavLink to={routersPath.BASES_CLIENT_FIND}>
                      <span>Поиск клиента</span>
                    </NavLink>
                  ),
                  icon: <SearchOutlined />,
                },
                {
                  key: routersPath.BASES_DOUBLE_FIND,
                  label: (
                    <NavLink to={routersPath.BASES_DOUBLE_FIND}>
                      <span>Поиск дублей</span>
                    </NavLink>
                  ),
                  icon: <TeamOutlined />,
                },
              ]}
            />
          }
        >
          <Space>
            <span>
              <DatabaseOutlined />
              Базы данных
            </span>

            <DownOutlined />
          </Space>
        </DropdownUI>
      ),
      pages: [
        routersPath.BASES,
        routersPath.BASE,
        routersPath.BASES_IMPORT,
        routersPath.BASES_CLIENT_FIND,
        routersPath.BASES_DOUBLE_FIND,
        routersPath.CLIENT_PAGE,
      ],
    },
    {
      show: true,
      title: (
        <DropdownUI
          menu={
            <Menu
              items={[
                {
                  disabled: false,
                  key: routersPath.MANAGER_SETTINGS,
                  label: (
                    <NavLink to={routersPath.MANAGER_SETTINGS}>
                      <span>Предустановки</span>
                    </NavLink>
                  ),
                  icon: <SettingFilled />,
                },
                {
                  disabled: false,
                  key: routersPath.MANAGERS_PAGE,
                  label: (
                    <NavLink to={routersPath.MANAGERS_PAGE}>
                      <span>Менеджеры</span>
                    </NavLink>
                  ),
                  icon: <TeamOutlined />,
                },
                {
                  disabled: !permissions.whiteList,
                  key: routersPath.WHITE_LIST,
                  label: (
                    <NavLink to={routersPath.WHITE_LIST}>
                      <span>Белый список</span>
                    </NavLink>
                  ),
                  icon: <SettingOutlined />,
                },
                {
                  disabled: false,
                  key: routersPath.ONLINE_USERS,
                  label: (
                    <NavLink to={routersPath.ONLINE_USERS}>
                      <span>Юзеры онлайн</span>
                    </NavLink>
                  ),
                  icon: <SwapOutlined />,
                },
                {
                  disabled: !permissions.scripts,
                  key: routersPath.SCRIPTS_PAGE,
                  label: (
                    <NavLink to={routersPath.SCRIPTS_PAGE}>
                      <span>Скрипты</span>
                    </NavLink>
                  ),
                  icon: <SnippetsOutlined />,
                },
                {
                  disabled: !permissions.tests,
                  key: routersPath.TESTS,
                  label: (
                    <NavLink to={routersPath.TESTS}>
                      <span>Тесты</span>
                    </NavLink>
                  ),
                  icon: <CarryOutOutlined />,
                },
              ]}
            />
          }
        >
          <Space>
            <span>
              <SettingFilled />
              Настройки
            </span>

            <DownOutlined />
          </Space>
        </DropdownUI>
      ),
      pages: [
        routersPath.MANAGER_SETTINGS,
        routersPath.MANAGERS_PAGE,
        routersPath.WHITE_LIST,
        routersPath.ONLINE_USERS,
        routersPath.SCRIPTS_PAGE,
        routersPath.SCRIPT_EDIT,
        routersPath.SCRIPT_CREATE,
        routersPath.TESTS,
        routersPath.ADD_TEST,
      ],
    },
    {
      show: permissions.generator,
      title: (
        <NavLink to={routersPath.DOC_GEN}>
          <FileTextOutlined />
          Генератор документов
        </NavLink>
      ),
      pages: [routersPath.DOC_GEN, routersPath.DOC_PREVIEW],
    },
    // {
    //   title: 'Безопасность',
    //   index: 1,
    //   pages: [routersPath.STAT_PAGE],
    // },
  ]
}
