import React, { Suspense, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import styles from './dashboard.module.scss'

import useHeader from '../../Header/useHeader'
import PageSpinner from '../../ui/PageSpinner/PageSpinner'
import { LoadSpinContext } from '../../../context/loadSpinContext'
import { useUserRole } from '../../../store/selectors/auth'
import cn from 'classnames'
import { EUserRoles } from '../../../enums/roles'
import { SocketContext } from '../../../context/socketContext'
import useSocket from '../../../hooks/useSocket'
import { useModalContext } from '../../../context/modalContext'
import { IRecallDto } from '../../../dto/recall/recall.dto'
import RecallEventModal from '../../Modals/RecallEventModal/RecallEventModal'

const DashboardLayout: React.FC = () => {
  const { setModalComponent } = useModalContext()
  const header = useHeader()
  const role = useUserRole()
  const {
    socket,
    emit,
    subscribeToRecall,
    unSubscribeToRecall,
    usersStatus,
    updateRecall,
    updateTransmit,
  } = useSocket()
  const [recall, setRecall] = useState<IRecallDto>()

  useEffect(() => {
    subscribeToRecall(setRecall)
    return () => unSubscribeToRecall(setRecall)
  }, [])

  useEffect(() => {
    if (recall) {
      setModalComponent({ component: <RecallEventModal recall={recall} />, closable: false })
    }
  }, [recall])

  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <SocketContext.Provider
      value={{
        socket,
        emit,
        subscribeToRecall,
        unSubscribeToRecall,
        usersStatus,
        updateRecall,
        updateTransmit,
      }}
    >
      <div className={styles.container}>
        {header}
        <div
          className={cn(styles.body, {
            [styles.admin]: role === EUserRoles.ADMIN || role === EUserRoles.OPERATOR,
            [styles.manager]: role === EUserRoles.MANAGER,
          })}
        >
          <LoadSpinContext.Provider value={{ setLoad: setIsLoading, isLoad: isLoading }}>
            <Suspense fallback={<PageSpinner />}>
              <Outlet />
            </Suspense>
          </LoadSpinContext.Provider>
        </div>
        {isLoading && <PageSpinner className={styles.loadSpin} />}
      </div>
    </SocketContext.Provider>
  )
}

export default DashboardLayout
