import React from 'react'
import { Button, ButtonProps } from 'antd'
import cn from 'classnames'
import './ButtonUi.scss'

interface Props extends ButtonProps {
  color?: 'red' | 'green' | 'blue' | 'yellow' | 'grey'
  outlined?: boolean
  size?: 'small'
}

const ButtonUi: React.FC<Props> = ({ outlined, color = 'blue', className, children,size, ...props }) => {
  return (
    <Button
      {...props}
      className={cn(
        'buttonUi',
        {
          outlined: outlined,
        },
        `buttonUi_${color}`,
        className,
        {['small']: size==='small'}
      )}
    >
      {children}
    </Button>
  )
}

export default ButtonUi
