import React, { useCallback } from 'react'
import { ITransaction } from './payment.interface'
import styles from './PayModal.module.scss'
import ButtonUi from '../../ui/ButtonUi/ButtonUi'
import { fullDateFormat } from '../../../utils/time'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useMessage } from '../../../hooks/useMessage'
import axiosInstance from '../../../config/axios'
import { serverPath } from '../../../constants/api-endpoints'
import { errorMapper } from '../../../utils/errorMapper'
import { ErrorMessages } from '../../../constants/errors'
import QRCode from 'react-qr-code'

interface Props {
  setReload: React.Dispatch<React.SetStateAction<number>>
  transaction: ITransaction
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const TransactionCard: React.FC<Props> = ({ setReload, transaction, setLoading }) => {
  const { success, error } = useMessage()

  const reloadHandler = useCallback(() => {
    setReload((prev) => prev + 1)
  }, [setReload])

  const deleteHandler = useCallback(() => {
    setLoading(true)
    axiosInstance
      .delete(serverPath.BILLING + '/' + transaction.id)
      .then(() => {
        reloadHandler()
        success('Заявка удалена')
      })
      .catch((e) => {
        error(errorMapper(ErrorMessages.GET_DATA, e), ErrorMessages.SERVER_ERROR)
      })
      .finally(() => setLoading(false))
  }, [transaction])

  return (
    <div className={styles.transaction}>
      <div className={styles.title}>Открыта заявка на оплату:</div>
      <div className={styles.desc}>
        <span className={styles.subTitle}>Сумма оплаты:</span>
        <span className={styles.wallet}>
          {transaction.amount} {transaction.currency} ( {transaction.payway} )
        </span>
      </div>
      <div className={styles.desc}>
        <span className={styles.subTitle}>Кошелек для оплаты:</span>
        <span className={styles.wallet}>
          <CopyToClipboard
            text={transaction.wallet}
            onCopy={() => success(`Адрес кошелька скопирован`)}
          >
            <span>{transaction.wallet}</span>
          </CopyToClipboard>
        </span>
      </div>
      <div className={styles.qr}>
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={transaction.wallet}
          viewBox={`0 0 256 256`}
        />
      </div>
      <div className={styles.desc}>
        <span className={styles.subTitle}>Дата создания заявки:</span>
        <span>{fullDateFormat(transaction.createdAt)}</span>
      </div>
      <ButtonUi color='red' onClick={deleteHandler}>
        Отменить заявку
      </ButtonUi>
    </div>
  )
}

export default TransactionCard
