import React, { Suspense, useEffect, useState } from 'react'
import { getPrice } from '../../../store/currency/thunk'
import { useAppDispatch } from '../../../store/hooks'
import { useCurrencySelector } from '../../../store/selectors/currency'
import BoxUi from '../../ui/BoxUi/BoxUi'
import styles from './salary.module.scss'
import { Outlet, useLocation } from 'react-router-dom'
import useCurrentTab from '../../../hooks/useCurrentTab'
import { DatePicker, Tabs } from 'antd'
import { salaryTabs } from './salary.tabs'
import PageSpinner from '../../ui/PageSpinner/PageSpinner'
import moment from 'moment/moment'
import { SalaryWeekContext } from '../../../context/salaryWeek.context'
import { SalaryStatusesContext } from '../../../context/salaryStatuses.context'
import { SalaryTransferContext } from '../../../context/salaryTransfer.context'
import { SalaryCryptoDto } from '../../../pages/Salary/SalaryCrypto/SalaryCryptoPage'

const { TabPane } = Tabs

const SalaryLayout: React.FC = () => {
  const dispatch = useAppDispatch()
  const { btc, usd } = useCurrencySelector()
  const { pathname } = useLocation()
  const activeTab = useCurrentTab(pathname, salaryTabs)
  const [weekFilter, setWeekFilter] = useState<moment.Moment | null>(moment().startOf('week'))
  const [salaryStatuses, setSalaryStatuses] = useState({ isConfirm: false, isPreview: true })
  const [salaryTransfer, setSalaryTransfer] = useState<SalaryCryptoDto>()

  useEffect(() => {
    dispatch(getPrice()).then()
  }, [])

  return (
    <>
      <BoxUi className={styles.container}>
        <>
          <Tabs className={styles.tabs} defaultActiveKey='0' activeKey={String(activeTab)}>
            {salaryTabs.map((el, index) => (
              <TabPane tab={el.title} key={index} />
            ))}
          </Tabs>
          <div className={styles.weekSelect}>
            <p>Выбор недели:</p>
            <DatePicker
              allowClear={false}
              onChange={(month) => setWeekFilter(month)}
              picker='week'
              value={weekFilter}
            />
          </div>
          <p className={styles.title}>
            Курс BTC: <span className={styles.count}>&nbsp;&nbsp;{btc}&nbsp;$</span>, Курс USD:{' '}
            <span className={styles.count}>&nbsp;&nbsp;{usd}&nbsp;грн</span>
          </p>
        </>
      </BoxUi>
      <SalaryWeekContext.Provider value={{ weekFilter, setWeekFilter }}>
        <SalaryStatusesContext.Provider value={{ salaryStatuses, setSalaryStatuses }}>
          <SalaryTransferContext.Provider value={{ salaryTransfer, setSalaryTransfer }}>
            <Suspense fallback={<PageSpinner />}>
              <Outlet />
            </Suspense>
          </SalaryTransferContext.Provider>
        </SalaryStatusesContext.Provider>
      </SalaryWeekContext.Provider>
    </>
  )
}

export default SalaryLayout
